import { Component, OnInit } from '@angular/core';
import { ParameterService } from '@pulpo/pulpo-api';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  ribbonEnv: string;

  constructor(private parameterService: ParameterService) {}

  ngOnInit(): void {
    if (environment.apiUrl.includes('localhost')) {
      this.parameterService.search('RIBBON_LOCAL').subscribe(
        (insRes) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
          } else {
            this.ribbonEnv = 'local - development';
          }
        },
        () => (this.ribbonEnv = 'local - development')
      );
    }
    if (environment.apiUrl.includes('preprod')) {
      this.parameterService.search('RIBBON_DEV').subscribe(
        (insRes) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
          } else {
            this.ribbonEnv = 'development';
          }
        },
        () => (this.ribbonEnv = 'development')
      );
    }
    if (environment.apiUrl.includes('staging')) {
      this.parameterService.search('RIBBON_STAGING').subscribe(
        (insRes) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
          } else {
            this.ribbonEnv = 'staging';
          }
        },
        () => (this.ribbonEnv = 'staging')
      );
    }
  }
}
