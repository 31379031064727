import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'day-report-card',
  templateUrl: './day-report-card.component.html',
  styleUrls: ['./day-report-card.component.scss'],
  standalone: true,
  imports: [CommonModule, DatePipe],
})
export class DayReportCardComponent {
  @Input() dayReport: any;
}
