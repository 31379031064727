import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParameterEditComponent } from '../parameter-edit/parameter-edit.component';
import { environment } from '../../../../../environments/environment';
import { ParameterService } from '@pulpo/pulpo-api';
import { IParameter } from '@pulpo/pulpo-models';
import {
  PulpoSnackbarComponent,
  clearPossibleSubscriptions,
  compare,
} from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss'],
})
export class ParameterListComponent implements OnInit, OnDestroy {
  parameters: IParameter[];
  filteredParameters: IParameter[];
  parameterSource: MatTableDataSource<IParameter>;
  displayedColumns: string[];
  itemsPerPage: number;
  private parameterSubscription: Subscription;
  private parameterEditSubscription: Subscription;
  private parameterDeleteSubscription: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  currentSort: Sort;

  constructor(
    private parameterService: ParameterService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'key',
      'description',
      'value',
      'updateDate',
      'updateAuthor',
      'options',
    ];
    this.itemsPerPage = environment.itemsPerPage;

    this.loadParameters();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.parameterSubscription,
      this.parameterEditSubscription,
      this.parameterDeleteSubscription
    );
  }

  private loadParameters(): void {
    this.parameterSubscription = this.parameterService.query().subscribe({
      next: (res: HttpResponse<IParameter[]>) => {
        this.parameters = res.body || [];
        this.filteredParameters = [...this.parameters];
        this.sortParameters();
      },
      error: (err: Error) => {
        alert(err.message);
      },
    });
  }

  sortParameters(sort: Sort = { active: 'key', direction: 'asc' }) {
    if (!sort.direction) {
      sort = { active: 'key', direction: 'asc' };
    }

    this.currentSort = sort;

    const isAsc = sort.direction === 'asc';

    //TODO remove ? Hides the contract archive parameter because too large
    this.filteredParameters = this.filteredParameters.filter(
      (a) => a.key !== 'CONTRACT_ARCHIVE'
    );

    this.filteredParameters.sort((a: any, b: any) => {
      switch (sort.active) {
        case 'key':
          return compare(a.key, b.key, isAsc);
        default:
          return 0;
      }
    });

    this.setDisplayedAirports();
  }

  private setDisplayedAirports() {
    this.parameterSource = new MatTableDataSource(this.filteredParameters);
    this.parameterSource.paginator = this.paginator;
  }

  filterParameters(filteredAirports: IParameter[]) {
    this.filteredParameters = filteredAirports;
    this.sortParameters(this.currentSort);
  }

  openParameterEditDialog(key?: string): void {
    const param = this.parameters.find((c) => c.key === key);
    const dialogRef = this.dialog.open(ParameterEditComponent, {
      disableClose: false,
      data: { parameter: param },
      width: '850px',
      height: '350px',
    });

    clearPossibleSubscriptions(this.parameterEditSubscription);

    this.parameterEditSubscription = dialogRef.afterClosed().subscribe({
      next: (result: IParameter) => {
        if (result) {
          if (key) {
            const parameters: IParameter | undefined = this.parameters.find(
              (a) => a.key === key
            );
            for (const key in result) {
              (parameters as any)[key] = (result as any)[key];
            }
          } else {
            this.parameters.push(result);
          }
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Modification du paramètre',
              message:
                'Le paramètre ' +
                result.key +
                ' (' +
                result.description +
                ')' +
                ' a bien été ' +
                (key ? 'modifié' : 'créé') +
                '.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });
          this.parameterService.parametersChangedSubject.next(this.parameters);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
