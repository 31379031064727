import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@pulpo/pulpo-api';
import { User } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';

import { clearPossibleSubscriptions } from '../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
  user: User;
  editForm: FormGroup;
  private resultSubscription: Subscription;
  isSaving: boolean;
  forceDelete = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserEditComponent>,
    private userService: UserService
  ) {
    this.user = data.user;
  }

  ngOnInit(): void {
    this.isSaving = false;
    this.initUpdateForm();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  initUpdateForm(): void {
    this.editForm = this.fb.group({ ...this.user });
  }

  onSubmitForm(): void {
    this.isSaving = true;

    const updatedUser = {
      ...this.user,
      ...this.editForm.value,
    };

    this.subscribeToSaveResponse(this.userService.update(updatedUser));
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<User>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  private subscribeToDeleteResponse(result: Observable<HttpResponse<any>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: () => {
        this.isSaving = false;
        this.dialogRef.close(-1);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  askDelete() {
    this.forceDelete = true;
  }

  confirmDeletion() {
    this.isSaving = true;
    this.subscribeToDeleteResponse(this.userService.delete(this.user.id));
  }
}
