<div class="table-list">
  <div class="table-header">
    <div class="d-flex flex-row align-items-center ps-4">
      <mat-icon class="scale-2">construction</mat-icon>
      <h1 class="m-4 ">Paramètres</h1>
    </div>
  </div>

  <app-parameter-filter *ngIf="parameters" [parameters]="parameters"
    (filterEvent)="filterParameters($event)"></app-parameter-filter>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="parameterSource" matSort (matSortChange)="sortParameters($event)" class="w-100">
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Clé</th>
      <td mat-cell *matCellDef="let parameter">{{ parameter.key }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let parameter">{{ parameter.description }}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>
        Valeur
      </th>
      <td mat-cell *matCellDef="let parameter">{{ parameter.value }}</td>
    </ng-container>

    <ng-container matColumnDef="updateDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dernière modification</th>
      <td mat-cell *matCellDef="let parameter">
        {{ parameter.modificationDate | date : 'dd/MM/yyyy HH:mm'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="updateAuthor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Auteur</th>
      <td mat-cell *matCellDef="let parameter">
        {{ parameter.modificationAuthor }}
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let parameter">
        <div class="row buttons-container">
          <div>
            <button mat-icon-button matTooltip="Modifier le paramètre" (click)="openParameterEditDialog(parameter.key)">
              <mat-icon [style.color]="'var(--admin-color)'">edit</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[itemsPerPage]" showFirstLastButtons
    class="w-100 mat-paginator-sticky"></mat-paginator>
</div>