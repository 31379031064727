<div class="table-list">
  <div class="table-header">
    <div class="d-flex flex-row align-items-center ps-4">
      <mat-icon class="scale-2">location_city</mat-icon>
      <h1 class="m-4 ">Villes</h1>
    </div>
    <div>
      <button type="button" mat-raised-button class="m-4" (click)="openCityEditDialog()">
        <mat-icon>add</mat-icon> Créer une nouvelle ville
      </button>
    </div>
  </div>

  <app-city-filter *ngIf="cities" [cities]="cities" (filterEvent)="filterCities($event)"></app-city-filter>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="citySource" matSort (matSortChange)="sortCities($event)" class="w-100">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let city">{{ city.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let city">{{ city.name }}</td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pays</th>
      <td mat-cell *matCellDef="let city">
        {{ city.country?.name ? city.country.name + " • " : "" }}
        {{ city.country?.codeCountry }}
      </td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let city">
        <div class="row buttons-container">
          <div>
            <button mat-icon-button matTooltip="Éditer la ville" (click)="openCityEditDialog(city.id)">
              <mat-icon [style.color]="'var(--primary)'">edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Supprimer la ville" (click)="openCityDeleteDialog(city)">
              <mat-icon [style.color]="'var(--danger)'">delete</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[itemsPerPage]" showFirstLastButtons
    class="w-100 mat-paginator-sticky"></mat-paginator>
</div>