<div class="table-list">
  <div class="table-header">
    <div class="d-flex flex-row align-items-center ps-4">
      <mat-icon class="scale-2">flight</mat-icon>
      <h1 class="m-4 ">Aéroports</h1>
    </div>
    <div>
      <button type="button" mat-raised-button class="m-4" (click)="openAirportEditDialog()">
        <mat-icon>add</mat-icon> Créer un nouvel aéroport
      </button>
    </div>
  </div>

  <app-airport-filter *ngIf="airports" [airports]="airports"
    (filterEvent)="filterAirports($event)"></app-airport-filter>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="airportSource" matSort (matSortChange)="sortAirports($event)" class="w-100">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let airport">{{ airport.id }}</td>
    </ng-container>

    <ng-container matColumnDef="iata">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>IATA</th>
      <td mat-cell *matCellDef="let airport">{{ airport.iata }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Nom de l'aéroport
      </th>
      <td mat-cell *matCellDef="let airport">{{ airport.name }}</td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ville</th>
      <td mat-cell *matCellDef="let airport">
        {{ airport.city?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pays</th>
      <td mat-cell *matCellDef="let airport">
        {{ airport.city?.country?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let airport">
        <div class="row buttons-container">
          <div>
            <button mat-icon-button matTooltip="Éditer l'aéroport" (click)="openAirportEditDialog(airport.id)">
              <mat-icon [style.color]="'var(--primary)'">edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Supprimer l'aéroport" (click)="openAirportDeleteDialog(airport)">
              <mat-icon [style.color]="'var(--danger)'">delete</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[itemsPerPage]" showFirstLastButtons
    class="w-100 mat-paginator-sticky"></mat-paginator>
</div>