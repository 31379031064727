<div class="h-100 d-flex flex-column justify-content-between align-items-center mt-4">
    <h4 class="w-70 text-center">{{content}}</h4>
    <div class="d-flex flex-row justify-content-evenly align-items-center w-100">
        <button mat-raised-button class="btn-pulpo-red m-4" (click)="this.dialogRef.close(id)"
            data-testid="dialog-button-delete">
            <mat-icon>done</mat-icon>
            Oui, supprimer l'élément
        </button>
        <button mat-raised-button class="btn-pulpo m-4" (click)="this.dialogRef.close(-1)"
            data-testid="dialog-button-cancel">
            <mat-icon>close</mat-icon>
            Annuler
        </button>
    </div>
</div>