import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { BatchReport } from '@pulpo/pulpo-models';

@Injectable({
  providedIn: 'root',
})
export class BatchReportService extends ApiService<BatchReport> {
  constructor(
    protected http: HttpClient,
    @Inject(APP_CONFIG) environment: any
  ) {
    super(environment, http, 'batch/api/batch-reports');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8082';
    }
  }
}
