import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CountryEditComponent } from '../../../../components/settings/country/country-edit/country-edit.component';
import { environment } from '../../../../../environments/environment';
import { CountryService } from '@pulpo/pulpo-api';
import { Country, ICountry } from '@pulpo/pulpo-models';
import {
  DeleteDialogComponent,
  PulpoSnackbarComponent,
  clearPossibleSubscriptions,
  compare,
} from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit, OnDestroy {
  countries: Country[];
  filteredCountries: ICountry[];
  countrySource: MatTableDataSource<ICountry>;
  displayedColumns: string[];
  itemsPerPage: number;
  private countrySubscription: Subscription;
  private countryEditSubscription: Subscription;
  private countryDeleteSubscription: Subscription;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  currentSort: Sort;

  constructor(
    private countryService: CountryService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = ['id', 'code', 'name', 'options'];
    this.itemsPerPage = environment.itemsPerPage;

    this.loadCountries();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.countrySubscription,
      this.countryEditSubscription,
      this.countryDeleteSubscription
    );
  }

  private loadCountries(): void {
    this.countrySubscription = this.countryService.query().subscribe({
      next: (res: HttpResponse<Country[]>) => {
        this.countries = res.body || [];
        this.filteredCountries = [...this.countries];
        this.sortCountries();
      },
      error: (err: Error) => {
        alert(err.message);
      },
    });
  }

  sortCountries(sort: Sort = { active: 'name', direction: 'asc' }) {
    if (!sort.direction) {
      sort = { active: 'name', direction: 'asc' };
    }

    this.currentSort = sort;

    const columnPropertyMatch = {
      id: 'id',
      code: 'codeCountry',
      name: 'name',
    };

    const isAsc = sort.direction === 'asc';

    this.filteredCountries.sort((a, b) => {
      return compare(
        (a as any)[(columnPropertyMatch as any)[sort.active]],
        (b as any)[(columnPropertyMatch as any)[sort.active]],
        isAsc
      );
    });

    this.setDisplayedCountries();
  }

  private setDisplayedCountries() {
    this.countrySource = new MatTableDataSource(this.filteredCountries);
    this.countrySource.paginator = this.paginator;
  }

  filterCountries(filteredCountries: ICountry[]) {
    this.filteredCountries = filteredCountries;
    this.sortCountries(this.currentSort);
  }

  openCountryEditDialog(id?: number): void {
    const dialogRef = this.dialog.open(CountryEditComponent, {
      disableClose: false,
      data: { country: this.countries.find((c) => c.id === id) },
      width: '850px',
      height: '250px',
    });

    clearPossibleSubscriptions(this.countryEditSubscription);

    this.countryEditSubscription = dialogRef.afterClosed().subscribe({
      next: (result: Country) => {
        if (result) {
          if (id) {
            const country: ICountry | undefined = this.countries.find(
              (c) => c.id === id
            );
            for (const key in result) {
              (country as any)[key] = (result as any)[key];
            }
          } else {
            this.countries.push(result);
          }
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Modification du pays',
              message:
                'Le pays ' +
                result.name +
                ' (' +
                result.id +
                ')' +
                ' a bien été ' +
                (id ? 'modifié' : 'créé') +
                '.',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });

          this.countryService.countriesChangedSubject.next(this.countries);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openCountryDeleteDialog(country: Country): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      disableClose: false,
      data: {
        id: country.id,
        content:
          'Voulez-vous supprimer le pays suivant : ' +
          country.name +
          ' (' +
          country.id +
          ') ? ',
      },
      width: '30%',
    });

    clearPossibleSubscriptions(this.countryDeleteSubscription);

    this.countryDeleteSubscription = dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result !== -1) {
          this.countryService.delete(country.id).subscribe({
            next: () => {
              this.countries.splice(this.countries.indexOf(country), 1);
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: 'Modification du pays',
                  message:
                    'Le pays ' +
                    country.name +
                    ' (' +
                    country.id +
                    ')' +
                    ' a bien été supprimé.',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
              this.countryService.countriesChangedSubject.next(this.countries);
            },
            error: (err) => {
              console.error(err);
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
