<div class="edit-dialog">
  <div class="table-header d-flex flex-row justify-content-between align-items-center h-20">
    <h2 class="m-4">
      {{ editMode ? 'Edition' : 'Création' }} d'une compagnie aérienne
    </h2>
  </div>
  <mat-dialog-content>
    <form *ngIf="editForm" class="mt-2 w-100 bordered corners" name="editForm" role="form" novalidate
      [formGroup]="editForm" (ngSubmit)="onSubmitForm()">
      <div class="row w-100">
        <div class="col-sm-3" *ngIf="editMode">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>ID</mat-label>
            <input required matInput type="text" formControlName="id" readonly [disabled]="true" class="text-muted" />
          </mat-form-field>
        </div>

        <div [ngClass]="{ 'col-sm-3': editMode, 'col-sm-4': !editMode }">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>IATA</mat-label>
            <input required matInput type="text" formControlName="iata" />
          </mat-form-field>
        </div>

        <div [ngClass]="{ 'col-sm-3': editMode, 'col-sm-4': !editMode }">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nom</mat-label>
            <input required matInput type="text" formControlName="name" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div class="col-sm-2">
          <button mat-raised-button class="admin" [disabled]="editForm.invalid || isSaving || !editForm.dirty">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>