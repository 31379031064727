import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../../../environments/environment';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { clearPossibleSubscriptions } from '../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';
import { PulpoSnackbarComponent } from '@pulpo/pulpo-commons';
import { UserService } from '@pulpo/pulpo-api';
import { User } from '@pulpo/pulpo-models';
import dayjs from 'dayjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<User>;
  displayedColumns: string[];
  itemsPerPage: number;
  page: number;
  filter: any = {};
  loading = true;
  nbUsers: number;
  private userSubscription: Subscription;
  private userUpdateSubscription: Subscription;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'id',
      'login',
      'pseudo',
      'company',
      'role',
      'since',
      'options',
    ];

    this.dataSource = new MatTableDataSource([] as User[]);
    this.itemsPerPage = environment.itemsPerPage;
    this.page = 0;

    this.loadUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.loadUsers();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(
      this.userSubscription,
      this.userUpdateSubscription
    );
  }

  private loadUsers(): void {
    this.loading = true;
    const sort = this.sort && this.sort.active ? this.sort.active : 'id';
    const order =
      this.sort && this.sort.direction ? this.sort.direction : 'desc';

    this.userSubscription = this.userService
      .queryPage({
        page: this.page,
        size: this.itemsPerPage,
        sort: sort,
        direction: order,
        ...this.filter,
      })
      .subscribe({
        next: (res: HttpResponse<any>) => {
          console.log(res.body?.content);
          this.dataSource = new MatTableDataSource(res.body?.content);
          this.nbUsers = res.body?.totalElements;
          this.loading = false;
        },
      });
  }

  applyFilter(filter: any) {
    this.page = 0;
    this.filter = {};
    for (const key in filter) {
      if (filter[key]) {
        if (filter[key] instanceof Date) {
          console.log('date');
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else if (dayjs.isDayjs(filter[key])) {
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else {
          this.filter[key] = filter[key];
        }
      }
    }
    this.loadUsers();
  }

  changePage() {
    this.page = this.paginator.pageIndex;
    this.loadUsers();
  }

  sortChanged() {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.loadUsers();
  }

  onActivationToggle(user: User): void {
    const newUser: User = { ...user, active: !user.active };
    if (!newUser.company) {
      newUser.company = {};
    }

    clearPossibleSubscriptions(this.userUpdateSubscription);

    this.userUpdateSubscription = this.userService.update(newUser).subscribe({
      next: (u) => {
        user.active = u.body?.active;
      },
      error: (err) => {
        console.error(err);
        if (err.status === 500) {
          alert(
            `Un problème est survenu sur le serveur est l'utilisateur n° ${
              user.id
            } n'a pas pu être ${user.active ? 'désactivé' : 'activé'} :\n\n` +
              `${err.message}\n\n` +
              `Merci de contacter l'administrateur.`
          );
        }
      },
    });
  }

  openUserEditDialog(userId: number): void {
    const dialogRef = this.dialog.open(UserEditComponent, {
      disableClose: false,
      data: { user: this.dataSource.data.find((c) => c.id === userId) },
      width: '850px',
      height: '400px',
    });

    clearPossibleSubscriptions(this.userUpdateSubscription);

    this.userUpdateSubscription = dialogRef.afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          if (result === -1) {
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: "Modification de l'utilisateur",
                message: "L'utilisateur  a bien été supprimé.",
              },
              duration: 4000,
              panelClass: ['snackbar'],
            });
            this.ngOnInit();
          } else {
            const user: User | undefined = this.dataSource.data.find(
              (u) => u.id === userId
            );
            for (const key of [
              'email',
              'pseudo',
              'phone',
              'lastName',
              'firstName',
            ]) {
              (user as any)[key] = (result as any)[key];
            }
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: "Modification de l'utilisateur",
                message:
                  "L'utilisateur " +
                  result.login +
                  ' (' +
                  result.id +
                  ')' +
                  ' a bien été modifié' +
                  '.',
              },
              duration: 4000,
              panelClass: ['snackbar'],
            });
          }
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
