import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AirportService, CityService } from '@pulpo/pulpo-api';
import { IAirport, ICity, Airport } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';
import { sortByName } from '@pulpo/pulpo-commons';
import { clearPossibleSubscriptions } from '../../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';

@Component({
  selector: 'app-airport-edit',
  templateUrl: './airport-edit.component.html',
  styleUrls: ['./airport-edit.component.scss'],
})
export class AirportEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  airport?: IAirport;
  editForm: FormGroup;
  cities: ICity[];
  isSaving: boolean;
  resultSubscription: Subscription;
  sortByName = sortByName;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<AirportEditComponent>,
    private airportService: AirportService,
    private cityService: CityService,
    private fb: FormBuilder
  ) {
    this.airport = data.airport;
  }

  ngOnInit(): void {
    this.editMode = !!this.airport;
    this.isSaving = false;

    this.cityService.query().subscribe({
      next: (res) => {
        this.cities = <ICity[]>res.body;
        this.initForm();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  private initForm(): void {
    if (this.editMode) {
      this.initUpdateForm();
    } else {
      this.initCreationForm();
    }
  }

  private initUpdateForm(): void {
    this.editForm = this.fb.group({
      id: [this.airport?.id],
      iata: [this.airport?.iata],
      name: [this.airport?.name],
      city: [this.airport?.city ? this.airport.city.id : null],
    });
  }

  private initCreationForm(): void {
    this.editForm = this.fb.group({
      iata: [],
      name: [],
      city: [],
    });
  }

  onSubmitForm(): void {
    this.isSaving = true;
    const airport = this.getCreatedOrUpdatedAirport();

    if (this.editMode) {
      this.subscribeToSaveResponse(this.airportService.update(airport));
    } else {
      this.subscribeToSaveResponse(this.airportService.create(airport));
    }
  }

  private getCreatedOrUpdatedAirport(): IAirport {
    const { id, iata, name } = this.editForm.value;
    const city = this.cities.find((c) => c.id === this.editForm.value.city);

    return {
      ...new Airport(),
      id,
      iata,
      name,
      city,
    };
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<any>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
