import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AirportService } from '@pulpo/pulpo-api';
import { IAirport } from '@pulpo/pulpo-models';
import { compare, removeAccentsAndTurnToLowerCase } from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-airport-filter',
  templateUrl: './airport-filter.component.html',
  styleUrls: ['./airport-filter.component.scss'],
})
export class AirportFilterComponent implements OnInit, OnDestroy {
  @Input() airports: IAirport[];
  @Output() filterEvent: EventEmitter<IAirport[]> = new EventEmitter<
    IAirport[]
  >();
  filteredAirports: IAirport[];
  filterForm: FormGroup;
  private airportsChangedSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private airportService: AirportService
  ) {}

  ngOnInit(): void {
    this.filteredAirports = [...this.airports];
    this.initFilterForm();
    this.airportsChangedSubscription =
      this.airportService.parametersChangedSubject.subscribe((res) => {
        this.airports = res;
        this.onSelectionChange();
      });
  }

  ngOnDestroy(): void {
    this.airportsChangedSubscription.unsubscribe();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      id: [],
      iata: [],
      name: [],
      city: [],
      country: [],
    });
  }

  onSelectionChange() {
    this.applyAllFilters();
    this.filterEvent.emit(this.filteredAirports);
  }

  private applyAllFilters() {
    this.filteredAirports = [...this.airports];

    for (const filter in this.filterForm.value) {
      const value = this.filterForm.value[filter];
      if (value) {
        this.filteredAirports = this.filteredAirports.filter((a) => {
          let propertyValue;
          switch (filter) {
            case 'city':
              propertyValue = a.city ? a.city.name : null;
              break;
            case 'country':
              propertyValue =
                a.city && a.city.country ? a.city.country.name : null;
              break;
            default:
              propertyValue = (a as any)[filter];
          }

          return (
            propertyValue &&
            removeAccentsAndTurnToLowerCase(propertyValue.toString()).includes(
              removeAccentsAndTurnToLowerCase(value.toString())
            )
          );
        });
      }
    }
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filteredAirports = [...this.airports];
    this.filterEvent.emit(this.filteredAirports);
  }

  excludeAirportsWithNo(
    property: 'id' | 'iata' | 'name' | 'city' | 'country'
  ): IAirport[] {
    let airportsWithProp: IAirport[] = [];

    switch (property) {
      case 'city':
        for (const airport of this.filteredAirports) {
          if (
            !!airport.city &&
            !!airport.city.name &&
            !airportsWithProp.some((a: any) => {
              return a.city.name === airport.city?.name;
            })
          ) {
            airportsWithProp.push(airport);
          }
        }
        break;
      case 'country':
        for (const airport of this.filteredAirports) {
          if (
            !!airport.city &&
            !!airport.city.country &&
            !!airport.city.country.name &&
            !airportsWithProp.some((a: any) => {
              return a.city.country.name === airport.city?.country.name;
            })
          ) {
            airportsWithProp.push(airport);
          }
        }
        break;
      default:
        airportsWithProp = this.filteredAirports.filter((a) => {
          return !!a[property];
        });
    }

    return airportsWithProp.sort((a1: any, a2: any) => {
      switch (property) {
        case 'city':
          return compare(a1.city.name, a2.city.name, true);
        case 'country':
          return compare(a1.city.country.name, a2.city.country.name, true);
        default:
          return compare(a1[property], a2[property], true);
      }
    });
  }
}
