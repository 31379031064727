<div class="table-list">
  <div class="table-header">
    <div class="d-flex flex-row align-items-center ps-4">
      <mat-icon class="scale-2">flight</mat-icon>
      <h1 class="m-4 ">Compagnies aériennes</h1>
    </div>
    <div>
      <button type="button" mat-raised-button class="m-4" (click)="openAirlineEditDialog()">
        <mat-icon>add</mat-icon> Créer une nouvel compagnie aérienne
      </button>
    </div>
  </div>

  <app-airline-filter *ngIf="airlines" [airlines]="airlines"
    (filterEvent)="filterAirlines($event)"></app-airline-filter>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="airlineSource" matSort (matSortChange)="sortAirlines($event)" class="w-100">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let airline">{{ airline.id }}</td>
    </ng-container>

    <ng-container matColumnDef="iata">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>IATA</th>
      <td mat-cell *matCellDef="let airline">{{ airline.iata }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let airline">{{ airline.name }}</td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let airline">
        <div class="row buttons-container">
          <div>
            <button mat-icon-button matTooltip="Éditer la compagnie aérienne"
              (click)="openAirlineEditDialog(airline.id)">
              <mat-icon [style.color]="'var(--primary)'">edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Supprimer la compagnie aérienne"
              (click)="openAirlineDeleteDialog(airline)">
              <mat-icon [style.color]="'var(--danger)'">delete</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[itemsPerPage]" showFirstLastButtons
    class="w-100 mat-paginator-sticky"></mat-paginator>
</div>