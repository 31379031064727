<div class="menu">

  <div class="ribbon" *ngIf="this.ribbonEnv">
    <span *ngIf="this.ribbonEnv">{{ ribbonEnv }}</span>
  </div>

  <div class="logo-container" [routerLink]="['home']">
    <img class="logo" src="../../../assets/images/pulpo_logo.JPG" alt="logo" />
    <div class="logo-title">Admin Pulpo</div>
  </div>

  <button [routerLink]="['users']" routerLinkActive="active" class="corners zoom">
    <span>Users</span>
    <span class="material-icons text-light"> people </span>
  </button>
  <button [routerLink]="['company']" routerLinkActive="active" class="corners zoom">
    <span> Company </span>
    <span class="material-icons text-light"> location_city </span>
  </button>
  <button [routerLink]="['reporting']" routerLinkActive="active" class="corners zoom">
    <span> Reporting </span>
    <span class="material-icons text-light"> list </span>
  </button>
  <button [routerLink]="['batchreporting']" routerLinkActive="active" class="corners zoom">
    <span> Batch Reporting </span>
    <span class="material-icons text-light"> list </span>
  </button>
  <button [routerLink]="['tasks']" routerLinkActive="active" class="corners zoom">
    <span> Taches </span>
    <span class="material-icons text-light"> list </span>
  </button>
  <button [routerLink]="['settings']" routerLinkActive="active" class="corners zoom">
    <span> Paramétrages</span>
    <span class="material-icons text-light"> extension </span>
  </button>
  <button [routerLink]="['tools']" routerLinkActive="active" class="corners zoom">
    <span> Outils</span> <span class="material-icons text-light"> construction </span>
  </button>
</div>