import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CompanyComponent } from './components/company/company.component';
import { UsersComponent } from './components/users/users.component';
import { ToolsComponent } from './components/tools/tools.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { AdminAuthGuard } from '@pulpo/pulpo-commons';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: MainComponent,
    canActivateChild: [AdminAuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'company',
        component: CompanyComponent,
      },
      {
        path: 'reporting',
        loadChildren: () =>
          import('./features/reporting/reporting.module').then(
            (m) => m.ReportingModule
          ),
      },
      {
        path: 'batchreporting',
        loadChildren: () =>
          import('./features/batch-report/batch-report.module').then(
            (m) => m.BatchReportModule
          ),
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./features/tasks/tasks.module').then((m) => m.TaskModule),
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'tools',
        component: ToolsComponent,
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
