<div class="edit-dialog">
  <mat-card class="admin corners">
    <h6 class="font-italic">
      {{ editMode ? "Édition" : "Création" }} du paramètre
    </h6>
  </mat-card>
  <mat-dialog-content>
    <form *ngIf="editForm" class="mt-2 w-100 bordered corners" name="editForm" role="form" novalidate
      [formGroup]="editForm" (ngSubmit)="onSubmitForm()">
      <div class="row w-100">
        <div class="col-sm-6" *ngIf="editMode">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Clé</mat-label>
            <input required matInput type="text" formControlName="key" readonly [disabled]="true" class="text-muted" />
          </mat-form-field>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-sm-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Description</mat-label>
            <input required matInput type="text" formControlName="description" />
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Valeur</mat-label>
            <input required matInput type="text" formControlName="value" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div class="col-sm-2">
          <button mat-raised-button class="admin" [disabled]="editForm.invalid || isSaving || !editForm.dirty">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>