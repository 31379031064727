import { ICity } from '../admin';

export interface IAirport {
  id?: number;
  iata?: string;
  name?: string;
  city?: ICity;
  longitude?: number;
  latitude?: number;
}

export class Airport implements IAirport {
  constructor(
    public id?: number,
    public iata?: string,
    public name?: string,
    public city?: ICity,
    public longitude?: number,
    public latitude?: number
  ) {}
}
