<div *ngIf="dayReport" class="d-flex flex-column w-100 justify-content-center align-items-center ">
    <span class="w-100 h-20 text-center px-2 bg-blue">{{dayReport['xml'].date | date: 'dd/MM/YYYY'}}</span>
    <div class="w-100 d-flex flex-row justify-content-center align-items-center">
        <span class="w-50 my-1 me-1 px-2 bg-blue text-center">XML</span>
        <span class="w-50 my-1 px-2 bg-blue text-center">REST</span>
    </div>
    <div class="w-100 d-flex flex-row justify-content-center align-items-center">
        <div class="w-50 me-1 d-flex flex-column justify-content-center align-items-center">
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green text-center">OK</span>
                <span class="w-33 px-2 bg-orange text-center">KO</span>
                <span class="w-33 px-2 bg-orange-soft text-center">%</span>
            </div>

            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['xml'].availabilityOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['xml'].availabilityKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['xml'].ratioAvailabilityKO}}</span>
            </div>
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['xml'].quotedOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['xml'].quotedKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['xml'].ratioQuotedKO}}</span>
            </div>
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['xml'].bookedOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['xml'].bookedKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['xml'].ratioBookedKO}}</span>
            </div>
        </div>
        <div class="w-50 d-flex flex-column justify-content-center align-items-center">
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green text-center">OK</span>
                <span class="w-33 px-2 bg-orange text-center">KO</span>
                <span class="w-33 px-2 bg-orange-soft text-center">%</span>
            </div>

            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['rest'].availabilityOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['rest'].availabilityKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['rest'].ratioAvailabilityKO}}</span>
            </div>
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['rest'].quotedOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['rest'].quotedKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['rest'].ratioQuotedKO}}</span>
            </div>
            <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
                <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport['rest'].bookedOK}}</span>
                <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport['rest'].bookedKO}}</span>
                <span
                    class="w-33 px-2 background-color text-center text-white">{{dayReport['rest'].ratioBookedKO}}</span>
            </div>
        </div>
    </div>
</div>