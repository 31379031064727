import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountryService } from '@pulpo/pulpo-api';
import { ICountry } from '@pulpo/pulpo-models';
import { compare, removeAccentsAndTurnToLowerCase } from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-country-filter',
  templateUrl: './country-filter.component.html',
  styleUrls: ['./country-filter.component.scss'],
})
export class CountryFilterComponent implements OnInit, OnDestroy {
  @Input() countries: ICountry[];
  @Output() filterEvent: EventEmitter<ICountry[]> = new EventEmitter<
    ICountry[]
  >();
  filteredCountries: ICountry[];
  filterForm: FormGroup;
  private countriesChangedSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.filteredCountries = [...this.countries];
    this.initFilterForm();
    this.countriesChangedSubscription =
      this.countryService.countriesChangedSubject.subscribe((res) => {
        this.countries = res;
        this.onSelectionChange();
      });
  }

  ngOnDestroy(): void {
    this.countriesChangedSubscription.unsubscribe();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      id: [],
      code: [],
      name: [],
    });
  }

  onSelectionChange() {
    this.applyAllFilters();
    this.filterEvent.emit(this.filteredCountries);
  }

  private applyAllFilters() {
    const filterPropertyMatch = {
      id: 'id',
      code: 'codeCountry',
      name: 'name',
    };

    this.filteredCountries = [...this.countries];

    for (const filter in this.filterForm.value) {
      const value = this.filterForm.value[filter];
      if (value) {
        const property = (filterPropertyMatch as any)[filter];

        this.filteredCountries = this.filteredCountries.filter((c) => {
          return (
            (c as any)[property] &&
            removeAccentsAndTurnToLowerCase(
              (c as any)[property].toString()
            ).includes(removeAccentsAndTurnToLowerCase(value.toString()))
          );
        });
      }
    }
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filteredCountries = [...this.countries];
    this.filterEvent.emit(this.filteredCountries);
  }

  excludeCountriesWithNo(property: 'id' | 'codeCountry' | 'name'): ICountry[] {
    return this.filteredCountries
      .filter((c) => {
        return !!c[property];
      })
      .sort((c1: any, c2: any) => {
        return compare(c1[property], c2[property], true);
      });
  }
}
