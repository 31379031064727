<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="d-flex">
      <div class="w-10 id-field">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput type="text" formControlName="id" (keyup)="onSelectionChange()" />
          <!-- <mat-autocomplete
            autoActiveFirstOption
            #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let user of excludeUsersWithNo('id')"
              [value]="user.id"
            >
              {{ user.id }}
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>
      </div>

      <div class="w-20">
        <mat-form-field>
          <mat-label>Nom d'utilisateur</mat-label>
          <input matInput type="text" formControlName="email" (keyup)="onSelectionChange()" />
          <!-- <mat-autocomplete
            autoActiveFirstOption
            #nameAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let user of excludeUsersWithNo('email')"
              [value]="user.email"
            >
              {{ user.email }}
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>
      </div>

      <div class="w-15">
        <mat-form-field>
          <mat-label>Pseudo</mat-label>
          <input matInput type="text" formControlName="pseudo" (keyup)="onSelectionChange()" />
          <!-- <mat-autocomplete
            autoActiveFirstOption
            #pseudoAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let user of excludeUsersWithNo('pseudo')"
              [value]="user.pseudo"
            >
              {{ user.pseudo }}
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>
      </div>

      <div class="w-15">
        <mat-form-field>
          <mat-label>Pulpo Company</mat-label>
          <input matInput type="text" formControlName="companyName" (keyup)="onSelectionChange()" />
          <!-- <mat-autocomplete
            autoActiveFirstOption
            #companyAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let user of excludeUsersWithNo('company')"
              [value]="user.company?.name"
            >
              {{ user.company?.name }}
            </mat-option>
          </mat-autocomplete> -->
        </mat-form-field>
      </div>

      <div class="w-10">
        <mat-form-field>
          <mat-label>Rôle</mat-label>
          <input matInput type="text" formControlName="roleName" (keyup)="onSelectionChange()" />
        </mat-form-field>
      </div>

      <div class="w-20"></div>

      <div class="w-10 text-center">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>