<div class="table-container">
  <div class="table-list">
    <div class="table-header">
      <div class="d-flex flex-row align-items-center ps-4">
        <mat-icon class="scale-2">people</mat-icon>
        <h1 class="m-4 ">Pulpo Users</h1>
      </div>
    </div>

    <app-user-filter (filterEvent)="applyFilter($event)"></app-user-filter>

    <mat-divider></mat-divider>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged()" class="w-100">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let user">{{ user.id }}</td>
      </ng-container>
      <ng-container matColumnDef="login">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nom d'utilisateur
        </th>
        <td mat-cell *matCellDef="let user">{{ user.login }}</td>
      </ng-container>
      <ng-container matColumnDef="pseudo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pseudo</th>
        <td mat-cell *matCellDef="let user">
          {{ user.pseudo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pulpo Company</th>
        <td mat-cell *matCellDef="let user">{{ user.company?.name }}</td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rôle</th>
        <td mat-cell *matCellDef="let user">{{ user?.role?.name }}</td>
      </ng-container>
      <ng-container matColumnDef="since">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Depuis</th>
        <td mat-cell *matCellDef="let user">
          {{ user.createdDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <div class="row buttons-container">
            <div>
              <button mat-icon-button [matTooltip]="user.active ? 'Désactiver' : 'Activer'"
                (click)="onActivationToggle(user)">
                <mat-icon [ngClass]="{
                    'text-success': user.active,
                    'text-danger': !user.active
                  }">person</mat-icon>
              </button>
            </div>
            <div>
              <button mat-icon-button matTooltip="Éditer l'utilisateur" (click)="openUserEditDialog(user.id)">
                <mat-icon [style.color]="'var(--primary)'">edit</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [length]="nbUsers" (page)="changePage()" [pageSize]="itemsPerPage" showFirstLastButtons
      class="w-100 mat-paginator-sticky"></mat-paginator>
  </div>
</div>