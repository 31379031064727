import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserEditComponent } from './users/user-edit/user-edit.component';
import { AirlineListComponent } from './settings/airline/airline-list/airline-list.component';
import { AirlineEditComponent } from './settings/airline/airline-edit/airline-edit.component';
import { AirlineFilterComponent } from './settings/airline/airline-filter/airline-filter.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ParameterFilterComponent } from './tools/parameter/parameter-filter/parameter-filter.component';
import { ParameterEditComponent } from './tools/parameter/parameter-edit/parameter-edit.component';
import { ParameterListComponent } from './tools/parameter/parameter-list/parameter-list.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MenuComponent } from './menu/menu.component';
import { SettingsComponent } from './settings/settings.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { ToolsComponent } from './tools/tools.component';
import { CompanyComponent } from './company/company.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { CountryListComponent } from './settings/country/country-list/country-list.component';
import { CountryFilterComponent } from './settings/country/country-filter/country-filter.component';
import { CityListComponent } from './settings/city/city-list/city-list.component';
import { CityFilterComponent } from './settings/city/city-filter/city-filter.component';
import { AirportListComponent } from './settings/airport/airport-list/airport-list.component';
import { AirportFilterComponent } from './settings/airport/airport-filter/airport-filter.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserFilterComponent } from './users/user-filter/user-filter.component';
import { CityEditComponent } from './settings/city/city-edit/city-edit.component';
import { CountryEditComponent } from './settings/country/country-edit/country-edit.component';
import { AirportEditComponent } from './settings/airport/airport-edit/airport-edit.component';
import { MaterialModule } from '../material.module';
import {
  DeleteDialogComponent,
  MatButtonLoadingDirective,
  PulpoSnackbarComponent,
} from '@pulpo/pulpo-commons';

@NgModule({
  declarations: [
    MenuComponent,
    SettingsComponent,
    HomeComponent,
    UsersComponent,
    ToolsComponent,
    CompanyComponent,
    LoginComponent,
    MainComponent,
    CountryListComponent,
    CountryFilterComponent,
    CityListComponent,
    CityFilterComponent,
    AirportListComponent,
    AirportFilterComponent,
    UserListComponent,
    UserFilterComponent,
    CityEditComponent,
    CountryEditComponent,
    AirportEditComponent,
    UserEditComponent,
    AirlineListComponent,
    AirlineEditComponent,
    AirlineFilterComponent,
    ParameterFilterComponent,
    ParameterEditComponent,
    ParameterListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    CodemirrorModule,

    PulpoSnackbarComponent,
    MatButtonLoadingDirective,
    DeleteDialogComponent,
  ],
  exports: [MenuComponent, SettingsComponent],
})
export class ComponentsModule {}
