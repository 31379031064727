import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent implements OnInit {
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  filterForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFilterForm();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      id: [],
      email: [],
      pseudo: [],
      companyName: [],
      roleName: [],
    });
  }

  onSelectionChange() {
    this.filterEvent.emit(this.filterForm.value);
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filterEvent.emit(this.filterForm.value);
  }
}
