import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountryService } from '@pulpo/pulpo-api';
import { ICountry, Country } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';
import { clearPossibleSubscriptions } from '../../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';

@Component({
  selector: 'app-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.scss'],
})
export class CountryEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  country?: ICountry;
  editForm: FormGroup;
  isSaving: boolean;
  resultSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CountryEditComponent>,
    private countryService: CountryService,
    private fb: FormBuilder
  ) {
    this.country = data.country;
  }

  ngOnInit(): void {
    this.editMode = !!this.country;
    this.isSaving = false;

    this.initForm();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  private initForm(): void {
    if (this.editMode) {
      this.initUpdateForm();
    } else {
      this.initCreationForm();
    }
  }

  private initUpdateForm(): void {
    this.editForm = this.fb.group({
      id: [this.country?.id],
      code: [this.country?.codeCountry],
      name: [this.country?.name],
    });
  }

  private initCreationForm(): void {
    this.editForm = this.fb.group({
      code: [],
      name: [],
    });
  }

  onSubmitForm(): void {
    this.isSaving = true;
    const country = this.getCreatedOrUpdatedCountry();

    if (this.editMode) {
      this.subscribeToSaveResponse(
        this.countryService.update(country as Country)
      );
    } else {
      this.subscribeToSaveResponse(
        this.countryService.create(country as Country)
      );
    }
  }

  private getCreatedOrUpdatedCountry(): ICountry {
    const { id, code, name } = this.editForm.value;

    return {
      ...new Country(),
      id,
      name: name,
      codeCountry: code,
    };
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<ICountry>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
