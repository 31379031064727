import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard implements CanActivateChild {
  constructor(
    @Inject(APP_CONFIG) private environment: any,
    private http: HttpClient,
    private router: Router
  ) {}
  canActivateChild(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isTechAdmin();
  }

  private isTechAdmin(): Promise<boolean> {
    return this.fetch()
      .toPromise()
      .then((response) => {
        const account: any = response?.body;
        if (account) {
          if (account.email.includes(this.environment.adminEmail)) {
            return true;
          }
        }
        return this.redirectToLoginAndReturnFalse();
      })
      .catch(() => {
        return this.redirectToLoginAndReturnFalse();
      });
  }

  private fetch(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.environment.apiUrl + 'api/account', {
      observe: 'response',
    });
  }

  private redirectToLoginAndReturnFalse(): boolean {
    this.router.navigate(['/login']);

    return false;
  }
}
