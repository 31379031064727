import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable()
export class SchedulerAuthInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private environment: any) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request ||
      !request.url ||
      (request.url.startsWith('http') &&
        !(
          this.environment.schedulerApiUrl &&
          request.url.startsWith(this.environment.schedulerApiUrl)
        ))
    ) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: 'Basic b3JjaGlzQGVscHVscG8uaW86cHBvcmNoaXM=',
        Accept: '*/*',
      },
    });
    return next.handle(request);
  }
}
