import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParameterService } from '@pulpo/pulpo-api';
import { IParameter, Parameter } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';
import { clearPossibleSubscriptions } from '../../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';

@Component({
  selector: 'app-parameter-edit',
  templateUrl: './parameter-edit.component.html',
  styleUrls: ['./parameter-edit.component.scss'],
})
export class ParameterEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  parameter?: IParameter;
  editForm: FormGroup;
  isSaving: boolean;
  resultSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<ParameterEditComponent>,
    private parameterService: ParameterService,
    private fb: FormBuilder
  ) {
    this.parameter = data.parameter;
  }

  ngOnInit(): void {
    this.editMode = !!this.parameter;
    this.isSaving = false;
    this.initForm();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  private initForm(): void {
    if (this.editMode) {
      this.initUpdateForm();
    } else {
      this.initCreationForm();
    }
  }

  private initUpdateForm(): void {
    this.editForm = this.fb.group({
      key: [this.parameter?.key],
      description: [this.parameter?.description],
      value: [this.parameter?.value],
    });
    console.log(this.editForm);
  }

  private initCreationForm(): void {
    this.editForm = this.fb.group({
      key: [],
      description: [],
      value: [],
    });
  }

  onSubmitForm(): void {
    this.isSaving = true;
    const parameter = this.getCreatedOrUpdatedAirport();

    if (this.editMode) {
      this.subscribeToSaveResponse(
        this.parameterService.updateFromKey(parameter)
      );
    } else {
      this.subscribeToSaveResponse(this.parameterService.create(parameter));
    }
  }

  private getCreatedOrUpdatedAirport(): IParameter {
    const { key, description, value } = this.editForm.value;
    return {
      ...new Parameter(),
      key,
      description,
      value,
    };
  }

  private subscribeToSaveResponse(
    result: Observable<HttpResponse<IParameter>>
  ) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
