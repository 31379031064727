<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="d-flex">
      <div class="w-30 id-field">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput type="text" formControlName="id" (keyup)="onSelectionChange()"
            [matAutocomplete]="idAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let city of excludeCitiesWithNo('id')" [value]="city.id">
              {{ city.id }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-30">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput type="text" formControlName="name" (keyup)="onSelectionChange()"
            [matAutocomplete]="nameAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #nameAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let city of excludeCitiesWithNo('name')" [value]="city.name">
              {{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-30">
        <mat-form-field>
          <mat-label>Pays</mat-label>
          <input matInput type="text" formControlName="country" (keyup)="onSelectionChange()"
            [matAutocomplete]="countryAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #countryAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let city of excludeCitiesWithNo('country')" [value]="city.country.name">
              {{ city.country.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="w-10 text-center">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>