export interface ICountry {
  id?: number;
  codeCountry?: string;
  name?: string;
}

export class Country implements ICountry {
  codeCountry!: string;
  id!: number;
  name!: string;
}
