import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PulpoSnackbarComponent } from '@pulpo/pulpo-commons';
import {
  AccommodationProviderSettingsService,
  CarRentalProviderSettingsService,
  CompanyService,
  FlightProviderSettingsService,
  InsuranceProviderSettingsService,
} from '@pulpo/pulpo-api';
import {
  AccommodationProviderEnum,
  AccommodationProviderSettings,
  CarRentalProviderEnum,
  CarRentalProviderSettings,
  Company,
  FlightProviderEnum,
  FlightProviderSettings,
  InsuranceProviderEnum,
  InsuranceProviderSettings,
} from '@pulpo/pulpo-models';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  companies: Company[];
  companyProducerMap: any;
  changeRoleLoading = false;
  isAdmin = false;
  selectedCompany: Company | null;
  FlightProviderEnum = FlightProviderEnum;
  CarRentalProviderEnum = CarRentalProviderEnum;
  InsuranceProviderEnum = InsuranceProviderEnum;
  AccommodationProviderEnum = AccommodationProviderEnum;
  carRentalProviderSettingsMap: {
    [key: string]: CarRentalProviderSettings;
  } = {};
  insuranceProviderSettingsMap: {
    [key: string]: InsuranceProviderSettings;
  } = {};
  flightProviderSettingsMap: {
    [key: string]: FlightProviderSettings;
  } = {};
  accommodationProviderSettingsMap: {
    [key: string]: AccommodationProviderSettings;
  } = {};

  constructor(
    protected http: HttpClient,
    protected companyService: CompanyService,
    protected flightProviderSettingsService: FlightProviderSettingsService,
    protected carRentalProviderSettingsService: CarRentalProviderSettingsService,
    protected insuranceProviderSettingsService: InsuranceProviderSettingsService,
    protected accommodationProviderSettingsService: AccommodationProviderSettingsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.companyProducerMap = new Map();
    this.companyService.findAll().subscribe((companyRes) => {
      if (companyRes.body) {
        console.log(companyRes.body);
        this.companies = companyRes.body;
        this.companies.forEach((comp) => {
          this.companyProducerMap.set(
            comp.id,
            comp.permit && comp.permit === 'P' ? 'P' : 'D'
          );
          this.carRentalProviderSettingsMap[comp.id] = {
            companyId: comp.id.toString(),
            cacheProviderList: [],
            bookingProviderList: [],
          };
          this.insuranceProviderSettingsMap[comp.id] = {
            companyId: comp.id.toString(),
            cacheProviderList: [],
            bookingProviderList: [],
          };
          this.flightProviderSettingsMap[comp.id] = {
            companyId: comp.id,
            cacheProviderList: [],
            bookingProviderList: [],
          };
          this.accommodationProviderSettingsMap[comp.id] = {
            companyId: comp.id.toString(),
            cacheProviderList: [],
            bookingProviderList: [],
          };
        });
        this.isAdmin = true;

        this.carRentalProviderSettingsService.findAll().subscribe((res) => {
          if (res.body) {
            this.companies.forEach((company) => {
              const carRentalProviderSettings = res.body?.find(
                (setting) => setting.companyId === company.id.toString()
              );
              if (carRentalProviderSettings) {
                this.carRentalProviderSettingsMap[company.id] =
                  carRentalProviderSettings;
              } else {
                this.carRentalProviderSettingsMap[company.id] = {
                  companyId: company.id.toString(),
                  cacheProviderList: [],
                  bookingProviderList: [],
                };
              }
            });
          }
        });

        this.insuranceProviderSettingsService.findAll().subscribe((res) => {
          if (res.body) {
            this.companies.forEach((company) => {
              const insuranceProviderSettings = res.body?.find(
                (setting) => setting.companyId === company.id.toString()
              );
              if (insuranceProviderSettings) {
                this.insuranceProviderSettingsMap[company.id] =
                  insuranceProviderSettings;
              } else {
                this.insuranceProviderSettingsMap[company.id] = {
                  companyId: company.id.toString(),
                  cacheProviderList: [],
                  bookingProviderList: [],
                };
              }
            });
          }
        });

        this.flightProviderSettingsService.findAll().subscribe((res) => {
          if (res.body) {
            this.companies.forEach((company) => {
              const flightProviderSettings = res.body?.find(
                (setting) =>
                  setting.companyId?.toString() === company.id.toString()
              );
              if (flightProviderSettings) {
                this.flightProviderSettingsMap[company.id] =
                  flightProviderSettings;
              } else {
                this.flightProviderSettingsMap[company.id] = {
                  companyId: company.id,
                  cacheProviderList: [],
                  bookingProviderList: [],
                };
              }
            });
          }
        });
        this.accommodationProviderSettingsService.findAll().subscribe((res) => {
          if (res.body) {
            this.companies.forEach((company) => {
              const accommodationProviderSettings = res.body?.find(
                (setting) => setting.companyId === company.id.toString()
              );
              if (accommodationProviderSettings) {
                this.accommodationProviderSettingsMap[company.id] =
                  accommodationProviderSettings;
              } else {
                this.accommodationProviderSettingsMap[company.id] = {
                  companyId: company.id.toString(),
                  cacheProviderList: [],
                  bookingProviderList: [],
                };
              }
            });
          }
        });
      }
    });
  }

  changeCompanyRole(event: MatSlideToggleChange, company: Company) {
    this.changeRoleLoading = true;
    if (event.checked) {
      this.companyProducerMap.set(company.id, 'P');
      company.permit = 'P';
    } else {
      this.companyProducerMap.set(company.id, 'D');
      company.permit = 'D';
    }
    this.companyService.update(company).subscribe(
      (res) => {
        this.changeRoleLoading = false;
        if (res.body) {
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Le rôle de la compagnie a changé',
              message:
                'Un mail a été envoyé au Super Administrateur de la compagnie ' +
                company.name,
            },
            duration: 8000,
            panelClass: ['snackbar'],
          });
        }
      },
      () => {
        this.changeRoleLoading = false;
      }
    );
  }

  changeActivation(event: MatSlideToggleChange, company: Company) {
    company.active = event.checked;
    this.companyService.update(company).subscribe((updateRes) => {
      if (updateRes.body) {
        this.snackBar.openFromComponent(PulpoSnackbarComponent, {
          data: {
            title: 'Le statut de la companie a changé !',
            message:
              'La PULPO Company ' +
              company.name +
              (company.active ? ' a été activée.' : ' a été désactivée.'),
          },
          duration: 8000,
          panelClass: ['snackbar'],
        });
      }
    });
  }

  showMore(company: Company) {
    if (this.selectedCompany && this.selectedCompany.id === company.id) {
      this.selectedCompany = null;
      return;
    }
    this.selectedCompany = company;
  }

  saveAll() {
    this.carRentalProviderSettingsService
      .updateAll(Object.values(this.carRentalProviderSettingsMap))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});
    this.insuranceProviderSettingsService
      .updateAll(Object.values(this.insuranceProviderSettingsMap))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});
    this.accommodationProviderSettingsService
      .updateAll(Object.values(this.accommodationProviderSettingsMap))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .subscribe(() => {});

    this.flightProviderSettingsService
      .updateAll(Object.values(this.flightProviderSettingsMap))
      .subscribe((res) => {
        if (res.body) {
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Les paramètres ont été sauvegardés !',
              message: 'Les paramètres ont été sauvegardés avec succès.',
            },
            duration: 8000,
            panelClass: ['snackbar'],
          });
        }
      });
  }
}
