import { TaskStatus } from './task-status.model';

export class Task {
  id?: string; // Assuming ObjectId is represented as a string
  name: string;
  cronChain?: string;
  httpMethod?: string;
  uri?: string;
  authorization?: string;
  payload?: string;
  active?: boolean;
  okReturnCodes?: number[];
  koResponseContent?: string;
  feedbackEmail?: string[];
  mailFeedbackCondition?: FeedbackConditionEnum;
  mailTemplate?: string;
  slackChannel?: string;
  slackFeedbackCondition?: FeedbackConditionEnum;
  timeout?: number;
  lastStatus: TaskStatus;
  running?: boolean;
}

export enum FeedbackConditionEnum {
  ALWAYS = 'ALWAYS',
  KO = 'KO',
  NEVER = 'NEVER',
}
