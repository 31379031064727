<div class="table-container">
  <div class="table-list">
    <div class="table-header">
      <div class="w-100 d-flex flex-rox justify-content-between">
        <div class="d-flex flex-row align-items-center ps-4">
          <mat-icon class="scale-2">location_city</mat-icon>
          <h1 class="m-4 ">Pulpo Company</h1>
        </div>
        <button mat-raised-button class="m-4 save-button" (click)="saveAll()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <div class="row justify-content-center m-3 w-100" *ngIf="this.changeRoleLoading">
      <mat-spinner diameter="50"></mat-spinner>
      <h4>Mise à jour en cours</h4>
    </div>
    <div *ngIf="!this.changeRoleLoading">
      <div class="row justify-content-center m-3 w-100" *ngFor="let company of companies">
        <mat-card class="w-100 m-3" style="min-height:75px!important;">
          <mat-card-header class="w-100">
            <div class="row w-100">
              <div class="col-sm-1">
                <button mat-icon-button (click)="showMore(company)">
                  <mat-icon style="color:black!important;">{{ company === selectedCompany ? 'keyboard_arrow_up':
                    'keyboard_arrow_down'}}</mat-icon>
                </button>
              </div>
              <div class="col-sm-1">
                <img *ngIf="company.logo" style="height:35px!important;" class="corners" alt="company logo"
                  [src]="'data:image/jpg;base64,' + company.logo">
                <mat-icon *ngIf="!company.logo" style="color:white!important;">business</mat-icon>
              </div>
              <div class="col-sm-3">
                <h5> {{company.name}} </h5>
              </div>
              <div class="col-sm-4">
                <mat-slide-toggle (change)="changeActivation($event,company)" [checked]="company.active === true">
                  <h5 [class.colored]="company.active === true" [class.red-colored]="company.active === false">
                    {{company.active ? "Active" : "Inactive"}}</h5>
                </mat-slide-toggle>

              </div>
              <div class="col-sm-3">
                <mat-slide-toggle (change)="changeCompanyRole($event,company)"
                  [checked]="this.companyProducerMap.get(company.id) === 'P'">{{this.companyProducerMap.get(company.id)
                  === "P" ? "Producteur" : "Distributeur"}}</mat-slide-toggle>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content *ngIf="selectedCompany === company">
            <mat-divider></mat-divider>
            <div class="row justify-content-start m-5">
              <div class="col-sm-2">FLIGHT</div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Sources du cache dynamique</mat-label>
                  <mat-select required multiple [(value)]="flightProviderSettingsMap[company.id].cacheProviderList">
                    <mat-option *ngFor="let cacheSource of FlightProviderEnum | keyvalue"
                      [value]="cacheSource.key">{{cacheSource.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Réservations dynamiques disponibles</mat-label>
                  <mat-select required multiple [(value)]="flightProviderSettingsMap[company.id].bookingProviderList">
                    <mat-option *ngFor="let bookingSource of FlightProviderEnum | keyvalue"
                      [value]="bookingSource.key">{{bookingSource.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <mat-divider></mat-divider>
            <div class="row justify-content-start m-5">
              <div class="col-sm-2">Location de voiture</div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Sources du cache dynamique</mat-label>
                  <mat-select required multiple [(value)]="carRentalProviderSettingsMap[company.id].cacheProviderList">
                    <mat-option [value]="CarRentalProviderEnum.PULPO">{{CarRentalProviderEnum.PULPO}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Réservations dynamiques disponibles</mat-label>
                  <mat-select required multiple
                    [(value)]="carRentalProviderSettingsMap[company.id].bookingProviderList">
                    <mat-option *ngFor="let bookingSource of CarRentalProviderEnum | keyvalue"
                      [value]="bookingSource.key">{{bookingSource.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
            </div>

            <mat-divider></mat-divider>
            <div class="row justify-content-start m-5">
              <div class="col-sm-2">Assurances</div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Sources du cache dynamique</mat-label>
                  <mat-select required multiple [(value)]="insuranceProviderSettingsMap[company.id].cacheProviderList">
                    <mat-option [value]="InsuranceProviderEnum.PULPO">{{InsuranceProviderEnum.PULPO}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Réservations dynamiques disponibles</mat-label>
                  <mat-select required multiple
                    [(value)]="insuranceProviderSettingsMap[company.id].bookingProviderList">
                    <mat-option *ngFor="let insuranceSource of InsuranceProviderEnum | keyvalue"
                      [value]="insuranceSource.key">{{insuranceSource.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
            </div>
            <mat-divider></mat-divider>
            <div class="row justify-content-start m-5">
              <div class="col-sm-2">Hébergements</div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Sources du cache dynamique</mat-label>
                  <mat-select required multiple
                    [(value)]="accommodationProviderSettingsMap[company.id].cacheProviderList">
                    <mat-option
                      [value]="AccommodationProviderEnum.PULPO">{{AccommodationProviderEnum.PULPO}}</mat-option>
                    <mat-option
                      [value]="AccommodationProviderEnum.WEBHOTELIER">{{AccommodationProviderEnum.WEBHOTELIER}}</mat-option>
                    <mat-option
                      [value]="AccommodationProviderEnum.HOTELBEDS">{{AccommodationProviderEnum.HOTELBEDS}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Réservations dynamiques disponibles</mat-label>
                  <mat-select required multiple
                    [(value)]="accommodationProviderSettingsMap[company.id].bookingProviderList">
                    <mat-option *ngFor="let accommodationSource of AccommodationProviderEnum | keyvalue"
                      [value]="accommodationSource.key">{{accommodationSource.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>