export const removeAccentsAndTurnToLowerCase = (str: string): string => {
  return removeAccents(str.toLowerCase());
};

function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function listTostring(list: any[]): string {
  return list.join(', ');
}

export function stringToList(listString: string, isNumber: boolean): any[] {
  return listString.split(',').map((x) => {
    if (isNumber) {
      return parseInt(x, 10);
    }
    return x;
  });
}
