import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@pulpo/pulpo-api';
import { PulpoSnackbarComponent } from '@pulpo/pulpo-commons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  loginSubscription!: Subscription;
  isSaving = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  onPasswordSubmit(login: string, password: string): void {
    this.isSaving = true;
    this.loginSubscription = this.authService.login(login, password).subscribe({
      next: () => {
        this.router.navigate(['/reporting']).then((hasNavigated) => {
          if (!hasNavigated) {
            this.isSaving = false;
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: 'Une erreur est survenue !',
                message: 'Merci de réessayer avec des identifiants valides',
                backgroundColor: '#ec2b2b',
                accentColor: 'white',
              },
              duration: 3000,
              panelClass: ['snackbar'],
            });
          }
        });
      },
      error: (err) => {
        console.log(err);
        this.isSaving = false;
      },
    });
  }
}
