import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CityService } from '@pulpo/pulpo-api';
import { ICity } from '@pulpo/pulpo-models';
import { compare, removeAccentsAndTurnToLowerCase } from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-city-filter',
  templateUrl: './city-filter.component.html',
  styleUrls: ['./city-filter.component.scss'],
})
export class CityFilterComponent implements OnInit, OnDestroy {
  @Input() cities: ICity[];
  @Output() filterEvent: EventEmitter<ICity[]> = new EventEmitter<ICity[]>();
  filteredCities: ICity[];
  filterForm: FormGroup;
  private citiesChangedSubscription: Subscription;

  constructor(private fb: FormBuilder, private cityService: CityService) {}

  ngOnInit(): void {
    this.filteredCities = [...this.cities];
    this.initFilterForm();
    this.citiesChangedSubscription =
      this.cityService.citiesChangedSubject.subscribe((res) => {
        this.cities = res;
        this.onSelectionChange();
      });
  }

  ngOnDestroy(): void {
    this.citiesChangedSubscription.unsubscribe();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      id: [],
      name: [],
      country: [],
    });
  }

  onSelectionChange() {
    this.applyAllFilters();
    this.filterEvent.emit(this.filteredCities);
  }

  private applyAllFilters() {
    this.filteredCities = [...this.cities];

    for (const filter in this.filterForm.value) {
      const value = this.filterForm.value[filter];
      if (value) {
        this.filteredCities = this.filteredCities.filter((c) => {
          let propertyValue;
          switch (filter) {
            case 'country':
              propertyValue = c[filter] ? c[filter]['name'] : null;
              break;
            default:
              propertyValue = (c as any)[filter];
          }

          return (
            propertyValue &&
            removeAccentsAndTurnToLowerCase(propertyValue.toString()).includes(
              removeAccentsAndTurnToLowerCase(value.toString())
            )
          );
        });
      }
    }
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filteredCities = [...this.cities];
    this.filterEvent.emit(this.filteredCities);
  }

  excludeCitiesWithNo(property: 'id' | 'name' | 'country'): ICity[] {
    let citiesWithProp: ICity[];

    if (property === 'country') {
      citiesWithProp = [];
      for (const city of this.filteredCities) {
        if (
          !!city.country &&
          city.country.name &&
          !citiesWithProp.some((c) => {
            return c.country.name === city.country.name;
          })
        ) {
          citiesWithProp.push(city);
        }
      }
    } else {
      citiesWithProp = this.filteredCities.filter((c) => {
        return !!c[property];
      });
    }

    return citiesWithProp.sort((c1: any, c2: any) => {
      return property === 'country'
        ? compare(c1[property]['name'], c2[property]['name'], true)
        : compare(c1[property], c2[property], true);
    });
  }
}
