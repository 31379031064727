<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="d-flex">
      <div class="w-30 id-field">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput type="text" formControlName="id" (keyup)="onSelectionChange()"
            [matAutocomplete]="idAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let country of excludeCountriesWithNo('id')" [value]="country.id">
              {{ country.id }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-30">
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input matInput type="text" formControlName="code" (keyup)="onSelectionChange()"
            [matAutocomplete]="codeAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #codeAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let country of excludeCountriesWithNo('codeCountry')" [value]="country.codeCountry">
              {{ country.codeCountry }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-30">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput type="text" formControlName="name" (keyup)="onSelectionChange()"
            [matAutocomplete]="nameAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #nameAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let country of excludeCountriesWithNo('name')" [value]="country.name">
              {{ country.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="w-10 text-center">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>