<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="row w-100">
      <div class="col-sm-1">
      </div>
      <div class="col-sm-3">
        <mat-form-field>
          <mat-label>Clé</mat-label>
          <input matInput type="text" formControlName="key" (keyup)="onSelectionChange()"
            [matAutocomplete]="idAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let parameter of excludeAirportsWithNo('key')" [value]="parameter.key">
              {{ parameter.key }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>