import { ICountry } from '../destination';

export interface ICity {
  id: number;
  name: string;
  landOnly: boolean;
  country: ICountry;
  longitude: number;
  latitude: number;
}

export class City implements ICity {
  country!: ICountry;
  id!: number;
  landOnly!: boolean;
  name!: string;
  longitude!: number;
  latitude!: number;
}
