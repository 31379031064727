export interface IParameter {
  key?: string;
  value?: string;
  description?: string;
  modificationDate?: any;
  modificationAuthor?: string;
}

export class Parameter implements IParameter {
  description?: string;
  key?: string;
  value?: string;
  modificationAuthor?: string;
  modificationDate?: any;
}
