<div class="table-list">
  <div class="table-header">
    <div class="d-flex flex-row align-items-center ps-4">
      <mat-icon class="scale-2">public</mat-icon>
      <h1 class="m-4 ">Pays</h1>
    </div>
    <div>
      <button type="button" mat-raised-button class="m-4" (click)="openCountryEditDialog()">
        <mat-icon>add</mat-icon> Créer un nouveau pays
      </button>
    </div>
  </div>

  <app-country-filter *ngIf="countries" [countries]="countries"
    (filterEvent)="filterCountries($event)"></app-country-filter>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="countrySource" matSort (matSortChange)="sortCountries($event)" class="w-100">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let country">{{ country.id }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let country">{{ country.codeCountry }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let country">{{ country.name }}</td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let country">
        <div class="row buttons-container">
          <div>
            <button mat-icon-button matTooltip="Éditer le pays" (click)="openCountryEditDialog(country.id)">
              <mat-icon [style.color]="'var(--primary)'">edit</mat-icon>
            </button>
          </div>
          <div>
            <button mat-icon-button matTooltip="Supprimer le pays" (click)="openCountryDeleteDialog(country)">
              <mat-icon [style.color]="'var(--danger)'">delete</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[itemsPerPage]" showFirstLastButtons
    class="w-100 mat-paginator-sticky"></mat-paginator>
</div>