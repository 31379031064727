<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="d-flex">
      <div class="w-12 id-field">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput type="text" formControlName="id" (keyup)="onSelectionChange()"
            [matAutocomplete]="idAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airport of excludeAirportsWithNo('id')" [value]="airport.id">
              {{ airport.id }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-12">
        <mat-form-field>
          <mat-label>IATA</mat-label>
          <input matInput type="text" formControlName="iata" (keyup)="onSelectionChange()"
            [matAutocomplete]="iataAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #iataAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airport of excludeAirportsWithNo('iata')" [value]="airport.iata">
              {{ airport.iata }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-26">
        <mat-form-field>
          <mat-label>Nom de l'aéroport</mat-label>
          <input matInput type="text" formControlName="name" (keyup)="onSelectionChange()"
            [matAutocomplete]="nameAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #nameAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airport of excludeAirportsWithNo('name')" [value]="airport.name">
              {{ airport.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-20">
        <mat-form-field>
          <mat-label>Ville</mat-label>
          <input matInput type="text" formControlName="city" (keyup)="onSelectionChange()"
            [matAutocomplete]="cityAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #cityAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airport of excludeAirportsWithNo('city')" [value]="airport.city?.name">
              {{ airport.city?.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-20">
        <mat-form-field>
          <mat-label>Pays</mat-label>
          <input matInput type="text" formControlName="country" (keyup)="onSelectionChange()"
            [matAutocomplete]="countryAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #countryAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airport of excludeAirportsWithNo('country')" [value]="airport.city?.country?.name">
              {{ airport.city?.country?.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-10 text-center">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>