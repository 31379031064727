<div class="filter-panel filters">
  <form [formGroup]="filterForm">
    <div class="d-flex">
      <div class="w-12 id-field">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput type="text" formControlName="id" (keyup)="onSelectionChange()"
            [matAutocomplete]="idAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #idAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airline of excludeAirlinesWithNo('id')" [value]="airline.id">
              {{ airline.id }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-12">
        <mat-form-field>
          <mat-label>IATA</mat-label>
          <input matInput type="text" formControlName="iata" (keyup)="onSelectionChange()"
            [matAutocomplete]="iataAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #iataAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airline of excludeAirlinesWithNo('iata')" [value]="airline.iata">
              {{ airline.iata }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-33">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput type="text" formControlName="name" (keyup)="onSelectionChange()"
            [matAutocomplete]="nameAutoComplete" />
          <mat-autocomplete autoActiveFirstOption #nameAutoComplete="matAutocomplete"
            (optionSelected)="onSelectionChange()">
            <mat-option *ngFor="let airline of excludeAirlinesWithNo('name')" [value]="airline.name">
              {{ airline.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="w-33">
      </div>

      <div class="w-10 text-center">
        <button type="button" mat-flat-button matTooltip="Réinitialiser les filtres" (click)="onResetFilters()">
          <mat-icon>clear_all</mat-icon>
        </button>
      </div>
    </div>
  </form>
</div>