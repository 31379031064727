export interface IAirline {
  id?: number;
  name?: string;
  iata?: string;
}

export class Airline implements IAirline {
  iata!: string;
  id!: number;
  name!: string;
}
