import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AirlineService } from '@pulpo/pulpo-api';
import { IAirline } from '@pulpo/pulpo-models';
import { compare, removeAccentsAndTurnToLowerCase } from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-airline-filter',
  templateUrl: './airline-filter.component.html',
  styleUrls: ['./airline-filter.component.scss'],
})
export class AirlineFilterComponent implements OnInit {
  @Input() airlines: IAirline[];
  @Output() filterEvent: EventEmitter<IAirline[]> = new EventEmitter<
    IAirline[]
  >();
  filteredAirlines: IAirline[];
  filterForm: FormGroup;
  private airlinesChangedSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private airlineService: AirlineService
  ) {}

  ngOnInit(): void {
    this.filteredAirlines = [...this.airlines];
    this.initFilterForm();
    this.airlinesChangedSubscription =
      this.airlineService.airlinesChangedSubject.subscribe((res) => {
        this.airlines = res;
        this.onSelectionChange();
      });
  }

  ngOnDestroy(): void {
    this.airlinesChangedSubscription.unsubscribe();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      id: [],
      iata: [],
      name: [],
    });
  }

  onSelectionChange() {
    this.applyAllFilters();
    this.filterEvent.emit(this.filteredAirlines);
  }

  private applyAllFilters() {
    this.filteredAirlines = [...this.airlines];

    for (const filter in this.filterForm.value) {
      const value = this.filterForm.value[filter];
      if (value) {
        this.filteredAirlines = this.filteredAirlines.filter((a: IAirline) => {
          let propertyValue: any;
          switch (filter) {
            default:
              propertyValue = (a as any)[filter];
          }

          return (
            propertyValue &&
            removeAccentsAndTurnToLowerCase(propertyValue.toString()).includes(
              removeAccentsAndTurnToLowerCase(value.toString())
            )
          );
        });
      }
    }
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filteredAirlines = [...this.airlines];
    this.filterEvent.emit(this.filteredAirlines);
  }

  excludeAirlinesWithNo(
    property: 'id' | 'iata' | 'name' | 'company'
  ): IAirline[] {
    let airlinesWithProp: IAirline[] = [];

    switch (property) {
      default:
        airlinesWithProp = this.filteredAirlines.filter((a) => {
          return !!(a as any)[property];
        });
    }

    return airlinesWithProp.sort((a1, a2) => {
      switch (property) {
        default:
          return compare((a1 as any)[property], (a2 as any)[property], true);
      }
    });
  }
}
