<div class="login d-flex flex-column align-items-center">
  <img class="logo" src="../../../assets/images/pulpo_logo.JPG" alt="logo" />
  <mat-form-field class="w-50">
    <mat-label> Utilisateur </mat-label>
    <input matInput placeholder="Utilisateur" type="text" #login />
  </mat-form-field>
  <mat-form-field class="w-50">
    <mat-label> Mot de passe </mat-label>
    <input matInput placeholder="Mot de passe" type="password" #password />
  </mat-form-field>
  <button mat-raised-button [loading]="isSaving"
    (click)="onPasswordSubmit(login.value, password.value)">Valider</button>
</div>