import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AirlineService } from '@pulpo/pulpo-api';
import { IAirline, Company, Airline } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';
import { clearPossibleSubscriptions } from '../../../../../../../../libs/pulpo-commons/src/lib/utils/subscription-helper';

@Component({
  selector: 'app-airline-edit',
  templateUrl: './airline-edit.component.html',
  styleUrls: ['./airline-edit.component.scss'],
})
export class AirlineEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  airline?: IAirline;
  editForm: FormGroup;
  companies: Company[];
  isSaving: boolean;
  resultSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<AirlineEditComponent>,
    private airlineService: AirlineService,
    private fb: FormBuilder
  ) {
    this.airline = data.airline;
  }

  ngOnInit(): void {
    this.editMode = !!this.airline;
    this.isSaving = false;
    this.initForm();
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  private initForm(): void {
    if (this.editMode) {
      this.initUpdateForm();
    } else {
      this.initCreationForm();
    }
  }

  private initUpdateForm(): void {
    this.editForm = this.fb.group({
      id: [this.airline?.id],
      iata: [this.airline?.iata],
      name: [this.airline?.name],
    });
  }

  private initCreationForm(): void {
    this.editForm = this.fb.group({
      iata: [],
      name: [],
    });
  }

  onSubmitForm(): void {
    this.isSaving = true;
    const airline = this.getCreatedOrUpdatedAirline();

    if (this.editMode) {
      this.subscribeToSaveResponse(this.airlineService.update(airline));
    } else {
      this.subscribeToSaveResponse(this.airlineService.create(airline));
    }
  }

  private getCreatedOrUpdatedAirline(): IAirline {
    const { id, iata, name } = this.editForm.value;
    return {
      ...new Airline(),
      id,
      iata,
      name,
    };
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<IAirline>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
