import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ParameterService } from '@pulpo/pulpo-api';
import { IParameter } from '@pulpo/pulpo-models';
import { compare, removeAccentsAndTurnToLowerCase } from '@pulpo/pulpo-commons';

@Component({
  selector: 'app-parameter-filter',
  templateUrl: './parameter-filter.component.html',
  styleUrls: ['./parameter-filter.component.scss'],
})
export class ParameterFilterComponent implements OnInit, OnDestroy {
  @Input() parameters: IParameter[];
  @Output() filterEvent: EventEmitter<IParameter[]> = new EventEmitter<
    IParameter[]
  >();
  filteredParameters: IParameter[];
  filterForm: FormGroup;
  private parametersChangedSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private parameterService: ParameterService
  ) {}

  ngOnInit(): void {
    this.filteredParameters = [...this.parameters];
    this.initFilterForm();
    this.parametersChangedSubscription =
      this.parameterService.parametersChangedSubject.subscribe((res) => {
        this.parameters = res;
        this.onSelectionChange();
      });
  }

  ngOnDestroy(): void {
    this.parametersChangedSubscription.unsubscribe();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      key: [],
      description: [],
      value: [],
    });
  }

  onSelectionChange() {
    this.applyAllFilters();
    this.filterEvent.emit(this.filteredParameters);
  }

  private applyAllFilters() {
    this.filteredParameters = [...this.parameters];

    for (const filter in this.filterForm.value) {
      const value = this.filterForm.value[filter];
      if (value) {
        this.filteredParameters = this.filteredParameters.filter((a) => {
          let propertyValue;
          switch (filter) {
            case 'key':
              propertyValue = a.key ? a.key : null;
              break;
            default:
              propertyValue = (a as any)[filter];
          }

          return (
            propertyValue &&
            removeAccentsAndTurnToLowerCase(propertyValue.toString()).includes(
              removeAccentsAndTurnToLowerCase(value.toString())
            )
          );
        });
      }
    }
  }

  onResetFilters(): void {
    this.initFilterForm();
    this.filteredParameters = [...this.parameters];
    this.filterEvent.emit(this.filteredParameters);
  }

  excludeAirportsWithNo(property: 'key'): IParameter[] {
    let parametersWithProp: IParameter[] = [];

    switch (property) {
      case 'key':
        for (const parameter of this.filteredParameters) {
          if (
            !!parameter.key &&
            !parametersWithProp.some((a) => {
              return a.key === parameter.key;
            })
          ) {
            parametersWithProp.push(parameter);
          }
        }
        break;
      default:
        parametersWithProp = this.filteredParameters.filter((a) => {
          return !!a[property];
        });
    }

    return parametersWithProp.sort((a1: any, a2: any) => {
      switch (property) {
        case 'key':
          return compare(a1.key, a2.key, true);
        default:
          return compare(a1[property], a2[property], true);
      }
    });
  }
}
