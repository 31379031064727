import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CityService, CountryService } from '@pulpo/pulpo-api';
import { clearPossibleSubscriptions, sortByName } from '@pulpo/pulpo-commons';
import { ICity, ICountry, City } from '@pulpo/pulpo-models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-city-edit',
  templateUrl: './city-edit.component.html',
  styleUrls: ['./city-edit.component.scss'],
})
export class CityEditComponent implements OnInit, OnDestroy {
  editMode: boolean;
  city?: ICity;
  editForm: FormGroup;
  countries: ICountry[];
  isSaving: boolean;
  resultSubscription: Subscription;
  sortByName = sortByName;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CityEditComponent>,
    private cityService: CityService,
    private countryService: CountryService,
    private fb: FormBuilder
  ) {
    this.city = data.city;
  }

  ngOnInit() {
    this.editMode = !!this.city;
    this.isSaving = false;

    this.countryService.query().subscribe({
      next: (res) => {
        this.countries = <ICountry[]>res.body;
        this.initForm();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngOnDestroy(): void {
    clearPossibleSubscriptions(this.resultSubscription);
  }

  private initForm(): void {
    if (this.editMode) {
      this.initUpdateForm();
    } else {
      this.initCreationForm();
    }
  }

  private initUpdateForm(): void {
    this.editForm = this.fb.group({
      id: [this.city?.id],
      name: [this.city?.name],
      country: [this.city?.country ? this.city.country.id : null],
    });
  }

  private initCreationForm(): void {
    this.editForm = this.fb.group({
      name: [],
      country: [],
    });
  }

  onSubmitForm(): void {
    this.isSaving = true;
    const city = this.getCreatedOrUpdatedCity();

    if (this.editMode) {
      this.subscribeToSaveResponse(this.cityService.update(city));
    } else {
      this.subscribeToSaveResponse(this.cityService.create(city));
    }
  }

  private getCreatedOrUpdatedCity(): ICity {
    const { id, name } = this.editForm.value;
    const country = this.countries.find(
      (c) => c.id === this.editForm.value.country
    );

    return {
      ...new City(),
      id,
      name,
      country: country ? country : {},
    };
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<ICity>>) {
    clearPossibleSubscriptions(this.resultSubscription);

    this.resultSubscription = result.subscribe({
      next: (res) => {
        this.isSaving = false;
        this.dialogRef.close(res.body);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
