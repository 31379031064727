import { Subscription } from 'rxjs';

export const clearPossibleSubscriptions = (
  ...subscriptions: Subscription[]
) => {
  for (const subscription of subscriptions) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
};
