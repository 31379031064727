import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { Observable } from 'rxjs';
import { Task, TaskStatus } from '@pulpo/pulpo-models';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends ApiService<Task> {
  constructor(
    protected http: HttpClient,
    @Inject(APP_CONFIG) environment: any
  ) {
    super(environment, http, 'ordo/api/tasks');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8085';
    }
  }

  run(id: string): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/run/${id}`,
      null,
      {
        observe: 'response',
      }
    );
  }

  enable(id: string): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/enable/${id}`,
      null,
      {
        observe: 'response',
      }
    );
  }

  disable(id: string): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${this.url}/${this.endpoint}/disable/${id}`,
      null,
      {
        observe: 'response',
      }
    );
  }

  getStatus(id: string): Observable<HttpResponse<TaskStatus>> {
    return this.http.get<TaskStatus>(
      `${this.url}/${this.endpoint}/status/${id}`,
      {
        observe: 'response',
      }
    );
  }
}
